import * as ActiveStorage from "@rails/activestorage";
import { Turbo } from "@hotwired/turbo-rails";
import "@rails/actiontext";
import "chartkick/chart.js";
import LocalTime from "local-time";

LocalTime.start();
ActiveStorage.start();
window.Turbo = Turbo;

import "../channels" // ActionCable Channels
import "../controllers" // Stimulus controllers
import "../config"

// Jumpstart Pro & other Functionality
const src = import.meta.glob("./src/**/*.js", { eager: true })
export default src;

import "../src/textarea_auto_grow";

// import { Idiomorph } from 'idiomorph/dist/idiomorph.esm.js'
// addEventListener("turbo:before-frame-render", (event) => {
//   event.detail.render = (currentElement, newElement) => {
//     Idiomorph.morph(currentElement, newElement, {
//       morphStyle: 'innerHTML'
//     })
//   }
// })

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import 'flowbite';
import "flowbite/dist/flowbite.turbo.js";

import debounced from 'debounced'
debounced.initialize({ ...debounced.events, input: { wait: 100 }, change: { wait: 100 } })


// require("@rails/activestorage").start();
// require("channels");
// require("trix");
// require("@rails/actiontext");
// require("jquery");

// // Stimulus controllers
// import "controllers";

// // Jumpstart Pro & other Functionality
// const components = require.context("src", true);
// components.keys().forEach(components);

// import "src/timezone";
// import LocalTime from "local-time";
// LocalTime.start();

// // ADD YOUR JAVASCRIPT HERE

// require.context("../images", true);

// // Styles
// // These are imported separately for faster Webpack recompilation
// // https://rubyyagi.com/solve-slow-webpack-compilation/
// import "stylesheets/base.scss";
// import "stylesheets/components.scss";
// import "stylesheets/utilities.scss";
// import "stylesheets/custom/app.scss";
// import "stylesheets/custom/textarea_auto_grow.scss";
// import "@yaireo/tagify/dist/tagify.css";
