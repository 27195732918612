
// This controller is used to drag and drop students between rooms
import Sortable from 'stimulus-sortable';

export default class extends Sortable {
  connect() {
    super.connect();

    this.modalFrame = document.querySelector('turbo-frame[id="modal"]');
    this.modalFrame.addEventListener('modal:closed', this.loadListener);
  }

  disconnect() {
    super.disconnect();
  }

  get options() {
    let options = super.options
    options["group"] = "drag-drop-student-room"
    options["sort"] = false
    options["onMove"] = this.onMove
    options["onEnd"] = this.onEnd.bind(this)
    return options
  }

  get defaultOptions() {
    return {
      animation: 200,
    }
  }

  // always add item to end of list
  onMove(event) {
    if (event.to !== event.from) {
      event.to.appendChild(event.dragged);
      return false;
    }
  }

  // show modal when dropped in new container
  onEnd(event) {
    if (event.to === event.from) {
      return
    }

    // dim the item while awaiting confirmation from modal
    event.item.querySelector('img')?.classList?.add(...this.dimmedClasses())

    // Show modal
    const url = this.modalUrl(event.item, event.to.dataset.roomId, event.to.dataset.contextDate)

    if (url) Turbo.visit(url.href, { frame: "modal" })
  }

  modalUrl(item, toRoomId, date) {
    if (item.dataset.transitionModalUrl) {
      let url = new URL(item.dataset.transitionModalUrl)
      url.searchParams.append("program_enrollment[room_id]", toRoomId)
      url.searchParams.append("program_enrollment[start_date]", date)
      url.searchParams.append("origin_url", window.location.href)
      return url
    }
  }

  dimmedClasses() {
    return ["opacity-90", "h-8", "w-8", "border-2", "border-dotted", "border-gray-300", "p-1"]
  }
}

